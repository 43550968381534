<template>
  <keep-alive v-if="$route.meta.keepalive" :key="key">
    <router-view />
  </keep-alive>
  <router-view v-else />
</template>

<script>
export default {
  name: "index",
  computed: {
    key() {
      return this.$route.name
        ? this.$route.name + +new Date()
        : this.$route + +new Date();
    }
  }
};
</script>

<style scoped></style>
